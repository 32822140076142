const URL = process.env.REACT_APP_BACKEND_URL

    export const uploadFile = async(file) => {
      if (!file) {
        console.error("No file selected");
        return;
      }
  
      const formdata = new FormData();
      formdata.append("file", file);
  
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };
  
      
  try {
    const response = await fetch(URL, requestOptions);
    const result = await response.json();
    console.log(result)
    return result;
  } catch (error) {
    console.error('Error:', error);
  }
    };
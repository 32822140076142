import React, { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { FaFile } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { AiOutlineFileUnknown } from "react-icons/ai";
import { uploadFile } from "../../api/Upload";
import { message } from "antd";

export default function Uploader({ onFileUpload, setLoading }) {
  const [files, setFiles] = useState([]);
  const fileInputRef = React.createRef(); // Create a ref for the file input

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).filter(file => file.type === "application/pdf");
    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      event.target.value = null; // Clear the input value to allow re-selection
    } else {
      message.error("Only PDF files are allowed.");
    }
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files).filter(file => file.type === "application/pdf");
    if (newFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } else {
      message.error("Only PDF files are allowed.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const truncateFileName = (name, length) => {
    if (name.length <= length) return name;
    return name.substring(0, length) + "...";
  };

  const handleUpload = async () => {
    if (files.length > 0) {
      setLoading(true);
      try {
        const result = await uploadFile(files[0]);
        setLoading(false);
        if (result) {
          onFileUpload(result);
        } else {
          message.error("Failed to upload file.");
        }
      } catch (error) {
        setLoading(false);
        message.error("An error occurred while uploading the file.");
      }
    } else {
      message.error("Please select a file first.");
    }
  };

  return (
    <>
      <div className="bg-white p-8 w-[90%] rounded-md xl:px-20 xl:max-w-[900px] 2xl:max-w-[1200px] 2xl:p-20">
        <div className="flex flex-col justify-between md:flex-row">
          <div
            className="border-2 border-dashed border-[#4496c5] rounded-lg p-6 mr-10 flex flex-col items-center justify-center w-[100%] md:w-[50%] xl:max-w-[45%] 2xl:h-[500px]"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <FiUpload size={55} className="text-[#4496c5] mt-10" />
            <p className="mb-4 font-medium text-md 2xl:text-xl mt-5 text-center">
              Drag and Drop files to Upload
            </p>
            <p className="mb-4 font-medium text-sm 2xl:text-md">Or</p>
            <label className="bg-[#4496c5] text-white text-lg px-14 py-2 rounded-md cursor-pointer hover:bg-[#4da6da]">
              Browse
              <input
                type="file"
                className="hidden"
                onChange={handleFileChange}
                accept="application/pdf"
                multiple
                ref={fileInputRef} // Attach the ref
              />
            </label>
            <p className="text-gray-400 mt-14 text-[13px]">
              Supported file type: PDF
            </p>
          </div>
          <div className="sm:w-1/2 ml-6">
            <h2 className="text-md 2xl:text-xl font-medium mb-4 mt-5 lg:mt-0">
              Uploaded files
            </h2>
            <ul>
              {files.length === 0 && (
                <div className="flex flex-col mt-4 xl:mt-[35%] justify-center items-center">
                  <AiOutlineFileUnknown size={30} />
                  <h1 className="mt-3 text-sm 2xl:text-md">No files uploaded</h1>
                </div>
              )}
              {files.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-2"
                >
                  <div className="flex items-center mt-3">
                    <FaFile className="text-blue-400" size={20} />
                    <span className="ml-3 text-md">
                      {truncateFileName(file.name, 25)}
                    </span>
                  </div>
                  <button
                    className="text-red-500 hover:text-red-700 mt-3"
                    onClick={() => handleFileRemove(index)}
                  >
                    <MdDelete size={20} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          className="flex justify-center items-center bg-white 2xl:mt-5 text-[#4496c5] font-semibold px-8 py-3 rounded-lg hover:bg-gray-50"
          onClick={handleUpload}
        >
          Continue
          <FaArrowRightLong className="ml-3" />
        </button>
      </div>
    </>
  );
}

// Loader.js
import React from 'react';

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex items-center justify-center space-x-2">
        <div className="w-4 h-4 border-4 border-blue-400 border-dotted rounded-full animate-spin"></div>
        <p className="text-blue-600">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;

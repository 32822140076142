import React, { useState } from "react";
import "./App.css";
import Uploader from "./components/common/Uploader";
import ExcelResults from "./components/common/ExcelResults";
import Loader from "./components/common/Loader"; // Assuming you have a Loader component

function App() {
  const [currentPage, setCurrentPage] = useState("uploader");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); 
  const handleFileUpload = (data) => {
    setData(data);
    setCurrentPage("results");
    setLoading(false); 
  };

  const handleBack = () => {
    setCurrentPage("uploader");
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center font-poppins bg-[#EFEFEF]">
      {loading ? (
        <Loader />
      ) : (
        <>
          {currentPage === "uploader" && (
            <Uploader onFileUpload={handleFileUpload} setLoading={setLoading} />
          )}
          {currentPage === "results" && (
            <ExcelResults onBack={handleBack} data={data} />
          )}
        </>
      )}
    </div>
  );
}

export default App;

import React, { useState } from "react";
import Spreadsheet from "react-spreadsheet";
import "./Excel.css";
import { MdLocalPrintshop, MdDownload } from "react-icons/md";
import { IoIosArrowRoundBack } from "react-icons/io";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const transformDataToSpreadsheetFormat = (data) => {
  let formattedData = [
    [
      {
        value: "Leasing Contract Summary",
        className: "leasecontractsummary-cell",
      },
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
      { value: "", className: "subtitle-cell" },
    ],
    [
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
    ],
  ];

  data.forEach((section) => {
    // Add section heading
    formattedData.push([
      { value: section.heading, className: "section-header-cell" },
      { value: "" },
      { value: "" },
      { value: "" },
    ]);

    section.content.forEach((row, index) => {
      if (index % 2 === 0) {
        const nextRow = section.content[index + 1];
        const newRow = [
          { value: row.key, className: "key-cell" },
          { value: row.value, className: "value-cell" },
          nextRow
            ? { value: nextRow.key, className: "key-cell" }
            : { value: "", className: "custom-cell" },
          nextRow
            ? { value: nextRow.value, className: "value-cell" }
            : { value: "", className: "custom-cell" },
        ];
        formattedData.push(newRow);
      }
    });

    // Add an empty row between sections for spacing
    formattedData.push([
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
      { value: "", className: "custom-cell" },
    ]);
  });

  return formattedData;
};

const ExcelResults = ({ onBack, data }) => {
  const [spreadsheetData, setSpreadsheetData] = useState(
    transformDataToSpreadsheetFormat(data)
  );

  // Handle spreadsheet data change
  const handleSpreadsheetChange = (data) => {
    setSpreadsheetData(data);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Set column widths
    worksheet.columns = [
      { width: 40 },
      { width: 40 },
      { width: 40 },
      { width: 40 },
    ];

    // Apply data and styles
    spreadsheetData.forEach((row, rowIndex) => {
      const excelRow = worksheet.addRow(row.map((cell) => cell.value));
      row.forEach((cell, colIndex) => {
        const excelCell = excelRow.getCell(colIndex + 1);
        if (cell.className) {
          applyStyles(excelCell, cell.className);
        }
      });

      // Set row heights
      excelRow.height = 20; // Set the height for each row
    });

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "spreadsheet.xlsx");
    });
  };

  const applyStyles = (cell, className) => {
    switch (className) {
      case "leasecontractsummary-cell":
        cell.font = { color: { argb: "FFD5601A" }, bold: true, size: 16 };
        cell.alignment = { horizontal: "left", vertical: "middle" };
        cell.height = 35; // Increase the height
        break;
      case "section-header-cell":
        cell.font = { size: 14 };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFF8DECF" },
        }; // light brown background
        cell.border = {
          top: { style: "thin", color: { argb: "FFD4772A" } },
          bottom: { style: "thin", color: { argb: "FFD4772A" } },
        };
        cell.alignment = { horizontal: "left", vertical: "middle" };
        break;
      case "key-cell":
        cell.font = { color: { argb: "FFFFFFFF" }, size: 12, bold: true };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF5D5D5D" },
        }; // dark gray background
        cell.border = {
          top: { style: "medium", color: { argb: "FFD4772A" } },
          bottom: { style: "thin", color: { argb: "FFD4772A" } },
          left: { style: "thin", color: { argb: "FFD4772A" } },
          right: { style: "thin", color: { argb: "FFD4772A" } },
        };
        cell.alignment = { horizontal: "left", vertical: "middle" };
        break;
      case "value-cell":
        cell.font = { color: { argb: "FF000000" }, size: 12 };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFE9E9E9" },
        }; // light gray background
        cell.border = {
          top: { style: "medium", color: { argb: "FFD4772A" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFD4772A" } },
          right: { style: "thin", color: { argb: "FFD4772A" } },
        };
        cell.alignment = { horizontal: "left", vertical: "middle" };
        break;
      case "custom-cell":
        cell.font = { size: 14 };
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFFFF" },
        }; // white background
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="w-[95%] h-[13%] md:w-[90%] flex justify-between space-x-4">
        <div
          className="back-button flex justify-center items-center hover:bg-[#f5f5f5]"
          onClick={onBack}
        >
          <IoIosArrowRoundBack size={25} />
          <button className="font-medium text-md ml-2">Back</button>
        </div>
        <div className="flex">
          <button
            onClick={handlePrint}
            className="print-button flex justify-center items-center mr-5 hover:bg-slate-50"
          >
            <MdLocalPrintshop className="mr-2" size={20} />
            <p className="font-medium">Print</p>
          </button>
          <button
            onClick={handleDownload}
            className="print-button flex justify-center items-center hover:bg-slate-50"
          >
            <MdDownload className="mr-2" size={20} />
            <p className="font-medium">Download</p>
          </button>
        </div>
      </div>
      <div className="h-[87%] overflow-y-auto flex flex-col w-[95%] md:w-[90%] mt-5">
        <Spreadsheet
          data={spreadsheetData}
          onChange={handleSpreadsheetChange}
        />
      </div>
    </>
  );
};

export default ExcelResults;
